import axios from "axios";

export async function getPlot() {
    const resp = await axios.get("/plots");
    return resp.data;
}

export async function getTop10() {
    const resp = await axios.get("/plots", {params: {"plot": "weekly_top_10"}});
    return resp.data;
}

export async function getTop7OneDay() {
    const resp = await axios.get("/plots", {params: {"plot": "top_7_1_day_plot"}});
    return resp.data;
}

export async function getTop20OneMonth() {
    const resp = await axios.get("/plots", {params: {"plot": "top_20_1_month_plot"}});
    return resp.data;
}

export async function getLivePlot() {
    const resp = await axios.get("/plots", {params: {"plot": "live"}});
    return resp.data
}

export async function getKeywords() {
    const resp = await axios.get("/keywordsv2");
    return resp.data;
}

export async function getPlotsByDate(date: string) {
    const resp = await fetch(`https://mb2uqb574q4jsfovkm6arkkk5e0jhgng.lambda-url.eu-central-1.on.aws/scraper/plots-by-date?date=${date}`)
    return resp.json()
}

export async function pingStat(page: string) {
    // axios.get("/heartbeat", {params: {"page": page}})
    fetch('https://saalsemy7zdnlznpldxbdxyqo40qsoly.lambda-url.eu-central-1.on.aws/')
}