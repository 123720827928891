import { useEffect, useState } from 'react';

import PlotRenderer from './PlotRenderer';
import { getPlot, getTop10, getTop7OneDay, getTop20OneMonth, pingStat, getLivePlot } from '../../lib/Api/api';
import "./Home.css";


function Home() {

    const [mainPlot, setMainPlot] = useState<any>();
    const [top10Plot, setTop10Plot] = useState<any>();
    const [top7OneDay, setTop7OneDay] = useState<any>();
    const [top20OneMonth, setTop20OneMonth] = useState<any>();
    const [live, setLive] = useState<any>();

    const [mainError, setMainError] = useState<boolean>(false);
    const [top10Error, setTop10Error] = useState<boolean>(false);
    const [top7Error, setTop7Error] = useState<boolean>(false);
    const [top20Error, setTop20Error] = useState<boolean>(false);
    const [liveError, setLiveError] = useState<boolean>(false);

    const [mainLayout, setMainLayout] = useState<any>();
    const [top10Layout, setTop10Layout] = useState<any>();
    const [top5OneDayLayout, setTop7OneDayLayout] = useState<any>();
    const [top20OneMonthLayout, setTop20OneMonthLayout] = useState<any>();
    const [liveLayout, setLiveLayout] = useState<any>();

    const [top5Loading, setTop7Loading] = useState<boolean>(true);
    const [top10Loading, setTop10Loading] = useState<boolean>(true);
    const [top20Loading, setTop20Loading] = useState<boolean>(true);
    const [spaghettiLoading, setSpaghettiLoading] = useState<boolean>(true);
    const [liveLoading, setLiveLoading] = useState<boolean>(true);
    

    useEffect(() => {
        getTop7OneDay().then((top5OneDay) => {
            setTop7OneDay(top5OneDay.data)
            setTop7OneDayLayout(setGraphXY(top5OneDay.layout))
            setTop7Loading(false);
        })
        .catch(() => setTop7Error(true))
        .finally(() => setTop7Loading(false));

        getTop10().then((top10Plot) => {
            setTop10Plot(top10Plot.data);
            setTop10Layout(setGraphXY(top10Plot.layout))
            setTop10Loading(false);
        })
        .catch(() => setTop10Error(true))
        .finally(() => setTop10Loading(false));

        getTop20OneMonth().then((top20OneMonth) => {
            setTop20OneMonth(top20OneMonth.data);
            setTop20OneMonthLayout(setTop20GraphXY(top20OneMonth.layout));
            setTop20Loading(false);
        })
        .catch(() => setTop20Error(true))
        .finally(() => setTop20Loading(false));

        getPlot().then((mainPlot) => {
            setMainPlot(mainPlot.data);
            if (window.innerWidth >= 500) {
                setMainLayout(setGraphXY(mainPlot.layout));
            } else {
                setMainLayout(mainPlot.layout);
            }
            setSpaghettiLoading(false);
        })
        .catch(() => setMainError(true))
        .finally(() => setSpaghettiLoading(false));

        getLivePlot().then((livePlot) => {
            setLive(livePlot.data);
            if (window.innerWidth >= 500) {
                setLiveLayout(setGraphXY(livePlot.layout));
            } else {
                setLiveLayout(livePlot.layout);
            }
            setLiveLoading(false);
        })
        .catch(() => setLiveError(true))
        .finally(() => setLiveLoading(false));
        pingStat("home");
    }, []);

    window.addEventListener("orientationchange", () => {
        // Plot's layout hook does not refresh for some reason.
        // Pretty bad but works for now.
        window.location.reload();
    })

    return (
        <div>
            <div className="title-wrapper">
                <div className="title">
                    <h2>Mire kellett figyelni az elmúlt időszakban?</h2>
                    <h3>
                        A kormánymédia aktuális célpontjai adatorientált, elfogulatlan módon összegyűjtve egy helyen.
                    </h3>
                </div>
            </div>
            <div className="react-plot-container">
                <div className="plot-wrapper">
                    <p>Valós idejű grafikon:</p>
                    <PlotRenderer
                        loading={liveLoading}
                        plotData={live}
                        plotLayout={liveLayout}
                        config={{responsive: true}}
                        error={liveError}
                    />
                    <p>Top 7 téma a mai napon:</p>
                    <PlotRenderer
                        loading={top5Loading}
                        plotData={top7OneDay}
                        plotLayout={top5OneDayLayout}
                        error={top7Error}
                    />
                    <p>Heti Top 10 téma:</p>
                    <PlotRenderer
                        loading={top10Loading}
                        plotData={top10Plot}
                        plotLayout={top10Layout}
                        error={top10Error}
                    />
                    <p>Heti Top 5 téma idővonal:</p>
                    <PlotRenderer
                        loading={spaghettiLoading}
                        plotData={mainPlot}
                        plotLayout={mainLayout}
                        config={{responsive: true}}
                        error={mainError}
                    />
                    <p>Havi Top 20 téma:</p>
                    <PlotRenderer
                        loading={top20Loading}
                        plotData={top20OneMonth}
                        plotLayout={top20OneMonthLayout}
                        error={top20Error}
                    />
                </div>
            </div>
            <div className="scroll-helper"/>
        </div>
    )
}

// Arbitary values that looked relitvely good.
export function setGraphXY(layout: any) {
    if (window.innerWidth > 900) {
        layout.height = 500;
        layout.width = 900;
    } else {
        layout.height = 450;
        layout.width = window.innerWidth;
        layout.legend.font.size = 10
    }
    return layout;
}
// Quick and dirty fix
export function setTop20GraphXY(layout: any) {
    if (window.innerWidth > 900) {
        layout.height = 1000;
        layout.width = 900;
    } else {
        layout.height = 800;
        layout.width = window.innerWidth;
    }
    return layout
}
export default Home;