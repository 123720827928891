import '../About/About.css'

function Support() {
    const supporters = [""]
    return (
    <div className="description-wrapper">
        <div className="description">
            <br/>
            <br/>
            <br/>
            <p>
                A szerver, adatbázis és domain név fenntartása, sajnos pénzbe kerül.
            </p>
            <p>
                Ha szeretnéd, hogy a guruló dollárokból az oldalnak is jusson,
                akkor <a href='https://www.buymeacoffee.com/foellenseg' target='_blank' rel="noreferrer">erre a linkre kattintva</a> tudsz segíteni.
            </p>
            <h3 className="description-header">
                Támogatók:
            </h3>
            <p>
                Itt lesz elérhető azoknak a támogatóknak a listája akik támogatták valaha az oldalt és megosztották a nevüket.
            </p>
            <ul>
                {supporters.map((supporter: string) => (<li>{supporter}</li>))}
            </ul>
        </div>
    </div>
    )
}

export default Support