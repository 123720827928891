import { useState, useEffect } from 'react';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';


import { getPlotsByDate } from '../../lib/Api/api';
import { setGraphXY, setTop20GraphXY } from '../Home/Home';
import PlotRenderer from '../Home/PlotRenderer';
import "../Home/Home.css";
import { CircularProgress } from '@material-ui/core';



const HistoricData = () => {
    let isMobile: boolean;
    const not_present = <p style={{color: "red"}}>A diagramm nem elérhető.</p>
    window.innerWidth < 900 ? isMobile = true : isMobile = false;
    const [date, setDate] = useState<Dayjs | null>();
    const [humanDate, setHumanDate] = useState<any>(new Date().toISOString().split('T')[0]);
    const [plotData, setPlotData] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const date = new Date().toISOString().split('T')[0];
        setDate(dayjs())
        getPlotsByDate(date).then((resp) => {
            setPlotData(resp);
            setLoading(false);
        })
        .catch((e) => {setErrorMessage("Szerverhiba.")});
        // pingStat("historic")
    }, [])

    window.addEventListener("orientationchange", () => {
        // Plot's layout hook does not refresh for some reason.
        // Pretty bad but works for now.
        window.location.reload();
    })

    useEffect(() => {
        if (date != null) {
            setHumanDate(date)
        }
    }, [date])


    function handleChange(newValue: any) {
        setDate(newValue);
        if (newValue != null ) {
            setLoading(true);
            getPlotsByDate(newValue.toISOString().split('T')[0]).then((resp) => {
                setPlotData(resp);
                setLoading(false);
            })
            .catch(() => setErrorMessage("Ez a nap nem érhető el."))
        }
    }

    

    return (
    <>
        <div className="title-wrapper">
            <div className="title">
                <h2>
                    Itt találhatók a múltban legtöbbet emlegetett témák.
                </h2>
            </div>
        </div>
        <div className="date-input-wrapper">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        {
            isMobile
            ?
            (
                <MobileDatePicker
                    label="Válassz Dátumot"
                    inputFormat="YYYY/MM/DD"
                    minDate={new Date("2022-11-01")}
                    maxDate={new Date()}
                    value={date}
                    onChange={handleChange}
                    renderInput={(params) => 
                        <TextField 
                        {...params}
                        sx={{
                            svg: { color: '#fff' },
                            input: { color: '#d8d8d8' },
                            label: { color: '#fff'},
                          }}
                        />
                }
                />
            )
            :
            (
                <DesktopDatePicker
                    label="Válassz Dátumot"
                    inputFormat="YYYY/MM/DD"
                    minDate={new Date("2022-11-01")}
                    maxDate={new Date()}
                    value={date}
                    onChange={handleChange}
                    renderInput={(params) => 
                        <TextField 
                        {...params}
                        sx={{
                            svg: { color: '#fff' },
                            input: { color: '#fff' },
                            label: { color: '#fff'},
                          }}
                        />
                }
                />
            )
        }
         </LocalizationProvider>
        </div>
        <div className="react-plot-container">
            {
                loading
                ?
                (
                    <>
                        <CircularProgress />
                    </>
                )
                :
                (
                    <>
                        {
                            errorMessage === '' 
                            ?
                            (
                                <div className="plot-wrapper">
                                    <p>Top 7 téma {humanDate.toISOString().split('T')[0]} napon:</p>
                                    {plotData.top7 ? (<PlotRenderer loading={false} plotData={plotData.top7[0].data} plotLayout={setGraphXY(plotData.top7[0].layout)}/>): (not_present)}
        
                                    <p>Top 10 téma {humanDate.subtract(6, 'day').toISOString().split('T')[0]} és {humanDate.toISOString().split('T')[0]} között:</p>
                                    {plotData.top7 ? (<PlotRenderer loading={false} plotData={plotData.top10[0].data} plotLayout={setGraphXY(plotData.top10[0].layout)}/>): (not_present)}
        
                                    <p>Top 5 téma idővonal {humanDate.subtract(6, 'day').toISOString().split('T')[0]} és {humanDate.toISOString().split('T')[0]} között:</p>
                                    {plotData.top7 ? (<PlotRenderer loading={false} plotData={plotData.spaghetti[0].data} plotLayout={window.innerWidth >= 500 ? setGraphXY(plotData.spaghetti[0].layout) : plotData.spaghetti[0].layout} config={{responsive: true}}/>): (not_present)}
        
                                    <p>Top 20 téma {humanDate.subtract(30, 'day').toISOString().split('T')[0]} és {humanDate.toISOString().split('T')[0]} között:</p>
                                    {plotData.top7 ? (<PlotRenderer loading={false} plotData={plotData.monthly[0].data} plotLayout={setTop20GraphXY(plotData.monthly[0].layout)}/>): (not_present)}
                                </div>
                            )
                            :
                            (
                                <p style={{color: "red"}}>{errorMessage}</p>
                            )
                        }
                    </>

                )
            }
        </div>
        <div className="historic-scroll-helper" />
    </>
    )
}



export default HistoricData;