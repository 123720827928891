import axios, { AxiosRequestConfig } from 'axios';
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Home from './Components/Home/Home';
import './App.css';
import About from './Components/About/About';
import NoutFound from './Components/NoutFound/NoutFound';
import NavBar from './Components/NavBar/NavBar';
import HistoricData from './Components/HistoricData/HistoricData';
import Support from './Components/Support/Support';

function App() {
    axios.interceptors.request.use(function(config: AxiosRequestConfig) {
        config.baseURL = process.env.REACT_APP_AXIOS_URL
        return config;
    });

    return(
        <>
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/historic-data" element={<HistoricData />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="*" element={<NoutFound />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
