import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getKeywords } from "../../lib/Api/api";
import "./About.css";


interface KeywordModel {
    [key: string]: string[];
  }

function About() {
    const [keywords, setKeywords] = useState<KeywordModel>({});

    useEffect(() => {
        getKeywords().then(keywords => setKeywords(keywords))
        // pingStat("about")
    }, [])

    interface ScrapedSite {
        link: string;
        site: string
    }

    const sites: ScrapedSite[] = [
        {link: 'https://origo.hu', site: "Origo"},
        {link: 'https://pestisracok.hu', site: "Pesti Srácok"},
        {link: 'https://mandiner.hu', site: "Mandiner"},
        {link: 'https://hirado.hu', site: "hirado.hu"},
        {link: 'https://vadhajtasok.hu', site: "Vadhajtások"},
        {link: 'https://888.hu', site: "888 (RIP)"},
        {link: 'https://metropol.hu', site: "Metropol"},
        {link: 'https://ripost.hu', site: "Ripost"},
    ]


    return (
        <div className="description-wrapper">
            <div className="description">
                <h3 className="description-header">
                    Cél:
                </h3>
                <p>
                    A kommunikáció jelenleg a bitek szintjén zajlik (Brüsszel 😠) ezért ez az oldal
                    a revolver médiát gúnyolja ki, követve régi foellenseg.hu mintáját,
                    amely már sajnos évek óta nem üzemel.
                </p>
                <p>
                    A weboldal létrehozója egyik oldallal sem szimpatizál. Az oldal azért jött létre, hogy a politikai kommunikáció
                    lezüllesztett állapotának tükröt tartson és kifigurázza azt.
                </p>
                <h3 className="description-header">
                    Támogatás:
                </h3>
                <p>
                    Sajnos a szerverek nem működnek ingyen.
                </p>
                <p>
                    Ha érdekel az oldal támogatása <Link to="/support">kattints ide</Link>
                </p>
                <h3 className="description-header">
                    Szakmai leirás:
                </h3>
                <p>
                    A weboldal mögött egy algoritmus különböző kormány média oldalait olvassa,
                    majd ezeket összesitve generál grafikonokat az elmúlt órák/napok legemlegetettebb témáiról.
                </p>
                    <div>
                        Jelenlegi megfigyelt oldalak:
                        <ul>
                            <div className="site-links">
                                {
                                    sites.map((site: ScrapedSite) => (
                                        <li key={site.link}><a href={site.link} target="_blank" rel="noreferrer">{site.site}</a></li>
                                    ))
                                }
                            </div>
                        </ul>
                    </div>
                <p>
                    A napi grafikon éjféltől számolja az említéseket, miközben a heti
                    és havi grafikonok az előző 7 és 30 napot vizsgálják.
                    A napi grafikon folyamatosan frissül és az átlag említéseket mutatja a nap végén.
                </p>
                <p>
                    A "Valós idejű grafikon" minden órában frissül felfoghatjuk a napi grafikonnak, amit órás felbontásban láthatunk.
                </p>
                <p>
                    Az adattárban a "Valós idejű grafikon" kivételével minden grafikon elérhető a dátum kiválasztásával 2022-11-01-jétől,
                    ha esetleg nem emlékeznénk hogy kit kellett utálni.
                </p>
                <p>
                    A jövőben tervezek hozzáadni egyéb funkcionalitásokat az oldalhoz,
                    jelenleg a szabadidőm a limitáló tényező az oldal fejlődésében.
                </p>
                    <div>
                        Jelenleg megfigyelt témák:
                        <ul>
                        <li>Téma: megfigyelt kulcsszavak</li>
                        {Object.entries(keywords).map(([key, values]) => (
                            <li key={key}>
                            {key}: {values.join(", ")}
                            </li>
                        ))}
                        </ul>
                    </div>
                <p>
                    Ha meglátásaid lennének az oldallal kapcsolatban, vagy csak szeretnéd a megfigyelt témák listáját bővíteni keress meg:
                    <p><a href="https://www.facebook.com/profile.php?id=100091667342228">facebook</a></p>
                    <p>ormis95@gmail.com</p>
                </p>
            </div>
        </div>
    )
}

export default About;
