import { CircularProgress } from "@material-ui/core";
import Plot from "react-plotly.js";

export interface PlotRendererProps {
    loading: boolean;
    plotData: any;
    plotLayout: any;
    config?: any;
    error?: boolean;
}

function PlotRenderer({loading, plotData, plotLayout, config, error}: PlotRendererProps) {
    return (
        <>
            {error ? 
            (
                <p style={{color: "red"}}>Hálózati hiba</p>
            )
            :
            (
                loading ?
                (
                    <CircularProgress />
                )
                :
                (
                    <Plot
                        data={plotData}
                        layout={plotLayout}
                        config={config || undefined}
                    />    
                )
            )
            }
        </>
    )
}

export default PlotRenderer;