import { Link } from "react-router-dom";
import "./NavBar.css";

function NavBar() {
    return (
        <div className="navbar-container">
            <Link to="/" className="navbar-link">
                Főoldal
            </Link>
            <Link to="/about" className="navbar-link">
                Leírás
            </Link>
            <Link to="/historic-data" className="navbar-link">
                Adattár
            </Link>
            {/* <Link to="asd" className="navbar-link">
                Támogatás
            </Link> */}
        </div>
    )
}

export default NavBar;
